<template>
  <div>
    <ResellerTable :parent="parent" />
  </div>
</template>

<script>
  import ResellerTable from "./ResellerTable.vue";
  import appConfig from "@/app.config";
  export default {
    page: {
      title: "Onhold sender IDs",
      meta: [{ name: "description", content: appConfig.description }],
    },
    name: "declinedReseller-sender_ids",
    components: {
      ResellerTable,
    },
    data() {
      return {
        parent: {
          name: "onhold",
          storeDataName: "resellerOnHoldSenderIds",
          from: "onhold",
          status: 3,
        },
      };
    },
    methods: {},
    computed: {},
    mounted() {},
  };
</script>

<style></style>
